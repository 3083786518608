import { createEnum } from "@/enums/baseEnum";
import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

export const salaryTypes = createEnum({
  Hourly: {
    value: "hourly",
    get label() {
      return $gettext("per hour");
    }
  },
  Daily: {
    value: "daily",
    get label() {
      return $gettext("per day");
    }
  },
  Weekly: {
    value: "weekly",
    get label() {
      return $gettext("per week");
    }
  },
  Monthly: {
    value: "monthly",
    get label() {
      return $gettext("per month");
    }
  },
  Annually: {
    value: "annual",
    get label() {
      return $gettext("per year");
    }
  }
});
