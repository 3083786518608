<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card ats-card">
          <div class="card-body">
            <div>
              <label
                ><i class="fa fa-building mr-2"></i>
                <translate>Company</translate></label
              >
              <p>{{ offer.company.name_fr }}</p>
            </div>
            <div>
              <label
                ><i class="fa fa-map-marker mr-2"></i
                ><translate>Address</translate></label
              >
              <p>{{ offer.location.address }}</p>
            </div>
            <div>
              <label
                ><i class="fa fa-eye mr-2"></i
                ><translate>Status</translate></label
              >
              <p>{{ offer.status }}</p>
            </div>
            <div v-if="offer.internal_reference_number">
              <label
                ><i class="fa fa-list-ol mr-2"></i
                ><translate>Reference number</translate></label
              >
              <p>{{ offer.internal_reference_number }}</p>
            </div>

            <div>
              <label
                ><i class="fa fa-cog mr-2"></i
                ><translate>Categories</translate></label
              >
              <p>{{ categories }}</p>
            </div>
            <div v-if="specializations.length">
              <label
                ><i class="fa fa-tools mr-2"></i
                ><translate>Specializations</translate></label
              >
              <p>{{ specializations }}</p>
            </div>
            <div v-if="experiences.length">
              <label
                ><i class="fa fa-hourglass mr-2"></i
                ><translate>Experiences</translate></label
              >
              <p>{{ experiences }}</p>
            </div>
            <div v-if="certifications.length">
              <label
                ><i class="fa fa-certificate mr-2"></i
                ><translate>Certifications</translate></label
              >
              <p>{{ certifications }}</p>
            </div>
            <div v-if="skills.length">
              <label
                ><i class="fa fa-star mr-2"></i
                ><translate>Skills</translate></label
              >
              <p>{{ skills }}</p>
            </div>
            <div v-if="educations.length">
              <label
                ><i class="fa fa-graduation-cap mr-2"></i
                ><translate>Educations</translate></label
              >
              <p>{{ educations }}</p>
            </div>
            <div v-if="jobType.length">
              <label
                ><i class="fa fa-calendar mr-2"></i
                ><translate>Schedule type</translate></label
              >
              <p>{{ jobType }}</p>
            </div>
            <div v-if="offer.salary_min">
              <label
                ><i class="fa fa-dollar-sign mr-2"></i
                ><translate>Salary (min)</translate></label
              >
              <p>{{ offer.salary_min }}</p>
            </div>
            <div v-if="offer.salary_max">
              <label
                ><i class="fa fa-dollar-sign mr-2"></i
                ><translate>Salary (max)</translate></label
              >
              <p>{{ offer.salary_max }}</p>
            </div>
            <div v-if="offer.salary_type">
              <label
                ><i class="fa fa-dollar-sign mr-2"></i
                ><translate>Rate</translate></label
              >
              <p>{{ getSalaryTypeLabel(offer.salary_type) }}</p>
            </div>
            <div>
              <label
                ><i class="fa fa-eye mr-2"></i
                ><translate>Created at</translate></label
              >

              <p><FormattedDate :date="offer.created_at" /></p>
            </div>
            <div>
              <label
                ><i class="fa fa-eye mr-2"></i
                ><translate>Updated at</translate></label
              >
              <p><FormattedDate :date="offer.updated_at" /></p>
            </div>
            <div>
              <label
                ><i class="fa fa-eye mr-2"></i
                ><translate>Published on</translate></label
              >
              <p>
                <FormattedDate
                  :date="offer.jobboards_max_jobboard_offerpublished_at"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <b-card no-body class="ats-card">
          <b-tabs pills card align="right" nav-wrapper-class="mb-3">
            <b-tab
              :title="index.toUpperCase()"
              v-for="(content, index) in offer.contents"
              :key="index"
            >
              <b-card-text v-html="content.description" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormattedDate from "@/components/FormattedDate";
import { BCard, BTabs, BTab, BCardText } from "bootstrap-vue";
import { salaryTypes } from "@/enums/salaryTypes";

export default {
  components: { FormattedDate, BCard, BCardText, BTabs, BTab },
  props: {
    offerId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapGetters("offers", { offer: "item" }),
    categories() {
      const attributes = this.offer.offer_attributes.category || [];
      return attributes.map(({ label }) => label).join(", ");
    },
    educations() {
      const attributes = this.offer.offer_attributes.education || [];
      return attributes.map(({ label }) => label).join(", ");
    },
    specializations() {
      const attributes = this.offer.offer_attributes.specialization || [];
      return attributes.map(({ label }) => label).join(", ");
    },
    experiences() {
      const attributes = this.offer.offer_attributes.experience || [];
      return attributes.map(({ label }) => label).join(", ");
    },
    certifications() {
      const attributes = this.offer.offer_attributes.certification || [];
      return attributes.map(({ label }) => label).join(", ");
    },
    jobType() {
      const attributes = this.offer.offer_attributes.schedule || [];
      return attributes.map(({ label }) => label).join(", ");
    },
    skills() {
      return (this.offer?.offer_attributes?.skill || [])
        .map(({ label }) => label)
        .join(", ");
    }
  },
  methods: {
    getSalaryTypeLabel(type) {
      return salaryTypes.from(type).label;
    }
  }
};
</script>
